import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import style from './Searchbar.scss'
import classNames from 'classnames'

export default class Searchbar extends React.Component {
  constructor ({ currentProduct }) {
    super()
    this.submit = this.submit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      product: currentProduct || ''
    }
  }

  handleChange (e) {
    this.setState({ product: e.target.value })
  }

  submit () {
    this.props.action({ product: this.state.product })
  }

  render () {
    const className = classNames(
      style.searchbox,
      { [style.disabled]: this.props.disabled }
    )
    return (
      <NamespacesConsumer ns={['home']}>
        {t => (
          <div className={className}>
            <div className={style.inputs}>
              <input
                value={this.state.product}
                name='product'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.submit()
                  }
                }}
                onChange={this.handleChange}
                placeholder={t('PRODUCT_PLACEHOLDER')}
                type='text'
                disabled={this.props.disabled} />
            </div>
            <button
              disabled={this.props.disabled}
              className={classNames(style.button, { [style.disabled]: this.props.disabled })}
              onClick={this.submit}
            >
              {t('GO')}
            </button>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
