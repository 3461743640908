import React from 'react'
import style from './Payment.scss'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import paymentActions from 'actions/payments'
import orderActions from 'actions/orders'
import QRCode from 'qrcode.react'
import Spinner from 'react-spinkit'
import revolupayLogoImg from '/../resources/img/home/revolupay-logo.png'
import appStoreImg from '/../resources/img/home/appstore.png'
import playStoreImg from '/../resources/img/home/playstore.png'

class Payment extends React.Component {
    checkPayment = () => {
      this.props.dispatch(paymentActions.status({ id: this.props.order.result.revolupayOrderReference }))
    }

    componentDidMount = () => {
      this.interval = setInterval(this.checkPayment, 2500)
    }

    componentWillUnmount = () => {
      clearInterval(this.interval)
      this.props.dispatch(paymentActions.clear())
      this.props.dispatch(orderActions.clear())
    }

    render () {
      if (this.props.payment.paid) {
        return (<Redirect to='/payment/success' />)
      }
      if (!this.props.order.result) {
        return (<Redirect to='/' />)
      }
      const reference = this.props.order.result.revolupayOrderReference
      return (
        <NamespacesConsumer ns={['payment']}>
          {t => (
            <div className={style.wrapper}>
              <div className={style.content}>
                <h1>{t('PAYMENT')}</h1>
                <h2>{t('INSTRUCTIONS')}</h2>
                <div className={style.spinner}>
                  <Spinner name='ball-scale-multiple' fadeIn='none' />
                </div>
                <div className={style.payment}>
                  <QRCode value={reference} size={600} />
                  <a href={`https://www.revolupay.es/pnp?action=complete_order&order_reference=${reference}`}>{t('PAY_IN_APP')}</a>
                </div>
                <h3>{t('COMPLETION')}</h3>
                <div className={style.disclosure}>
                  <div className={style.wrapper}>
                    <img src={revolupayLogoImg} />
                    <div className={style.downloadImgs}>
                      <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                      <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </NamespacesConsumer>
      )
    }
}

export default connect((state) => ({
  order: state.order,
  payment: state.payment
}))(Payment)
