import React from 'react'
const ApiContext = React.createContext({})
export const ApiProvider = ApiContext.Provider
export const ApiConsumer = ApiContext.Consumer
export const withApi = (WrappedComponent) => {
  const withHOC = (props) => {
    return (
      <ApiConsumer>
        {context => {
          return (<WrappedComponent {...props} api={context} />)
        }}
      </ApiConsumer>
    )
  }
  withHOC.WrappedComponent = WrappedComponent
  return withHOC
}
