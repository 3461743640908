export const PLACE = '@revolutility/PLACE_ORDER'
export const PUT = '@revolutility/PUT_ORDER'
export const ERROR = '@revolutility/ORDER_ERROR'
export const CLEAR = '@revolutility/ORDER_CLEAR'
export default ({
  place: params => ({ type: PLACE, params }),
  put: result => ({ type: PUT, result }),
  error: () => ({ type: ERROR }),
  clear: () => ({ type: CLEAR })
})
