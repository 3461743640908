import React from 'react'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import style from './Home.scss'

import Searchbar from '../shared/Searchbar'

import revoluchargeImg from '/../resources/img/home/revolucharge.png'
import revoloufinImg from '/../resources/img/home/revolufin.png'
import revolupayImg from '/../resources/img/home/revolupay.png'
import revolutilityImg from '/../resources/img/home/revolutility.png'
import revoluvipImg from '/../resources/img/home/revoluvip.jpg'

import billImg from '/../resources/img/home/bills.svg'
import revolupayLogoImg from '/../resources/img/home/revolupay-logo.png'
import appStoreImg from '/../resources/img/home/appstore.png'
import playStoreImg from '/../resources/img/home/playstore.png'

import { withApi } from 'services/ApiContext'
import { makeCancelable } from 'services/CancelablePromise'

class Home extends React.Component {
  constructor ({ i18n }) {
    super()
    this.i18n = i18n
    this.state = { bestSellers: [] }
  }

  componentDidMount () {
    this.fetchBestsellers = makeCancelable(this.props.api.getBestsellers())
    this.fetchBestsellers.promise.then(bestSellers => {
      this.setState({ bestSellers })
    }).catch(console.error)
  }

  componentWillMount () {
    if (this.fetchBestsellers) {
      this.fetchBestsellers.cancel()
    }
  }

  render () {
    const { history } = this.props
    return (
      <NamespacesConsumer ns={['home', 'categories']}>
        {t => (
          <div className={style.wrapper}>
            <div className={style.welcome}>
              <h1>{t('TITLE')}</h1>
              <h3>{t('SUBTITLE')}</h3>
              <Searchbar
                action={({ product }) => {
                  history.push(`/products?name=${product}&countryId=null`)
                }}
              />
              <div className={style.disclosure}>
                <div className={style.wrapper}>
                  <img src={revolupayLogoImg} />
                  <div className={style.downloadImgs}>
                    <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                    <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                  </div>
                  <div className={style.description}>
                    <span>{t('REWARDS', { reward: this.props.config.data.revolupayReward })} <a href='https://www.revoluvip.club'>{t('CLUB_REVOLUVIP')}</a></span>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.disclosure}>
              <div className={style.wrapper}>
                <img src={revolupayLogoImg} />
                <div className={style.downloadImgs}>
                  <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                  <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                </div>
                <div className={style.descriptionResponsive}>
                  <span>{t('REWARDS', { reward: this.props.config.data.revolupayReward })} <a href='https://www.revoluvip.club'>{t('CLUB_REVOLUVIP')}</a></span>
                </div>
              </div>
            </div>
            <div className={style.bestSellers}>
              <h1>{t('BESTSELLERS')}</h1>
              <div className={style.container}>
                {this.state.bestSellers.map(bestSeller => (
                  <div className={style.item} key={bestSeller.name} onClick={() => {
                    history.push(`/products?name=${bestSeller.name}&countryId=null`)
                  }}>
                    <img src={`${process.env.ASSETS}/${bestSeller.icon}`} alt={bestSeller.name} />
                  </div>
                ))}
              </div>
            </div>
            <div className={style.disclaimer}>
              <h1>{t('PLATFORM_DETAILS')}</h1>
              <img src={billImg} alt='' />
              <div className={style.button} onClick={() => {
                history.push('/products')
              }}>
                {t('BROWSE_PRODUCTS')}
              </div>
            </div>

            <div className={style.categoryContainer}>
              <h1>{t('CATEGORIES')}</h1>
              <div className={style.categories}>
                {this.props.categories.data.map(category => (
                  <div className={style.category} key={category.id} onClick={() => {
                    history.push(`products?categoryId=${category.id}&countryId=null`)
                  }}>
                    <img src={`${process.env.ASSETS}/${category.icon}`} />
                    <span>{t(`categories:${category.alias}`)}</span>
                  </div>
                ))
                }
              </div>
            </div>
            <div className={style.references}>
              <h1>{t('SERVICES')}</h1>
              <div className={style.products}>
                <div className={style.product}>
                  <a href='https://www.revolucharge.com'>
                    <img src={revoluchargeImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluCHARGE</span>
                    <span className={style.detail}>{t('REVOLUCHARGE')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolufin.com' target='_blank'>
                    <img src={revoloufinImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluFIN</span>
                    <span className={style.detail}>{t('REVOLUFIN')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolupay.es' target='_blank'>
                    <img src={revolupayImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluPAY</span>
                    <span className={style.detail}>{t('REVOLUPAY')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='#' target='_blank'>
                    <img src={revolutilityImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevolUTILITY</span>
                    <span className={style.detail}>{t('REVOLUTILITY')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='http://www.revoluvip.club' target='_blank'>
                    <img src={revoluvipImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>RevoluVIP</span>
                    <span className={style.detail}>{t('REVOLUVIP')}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({
  categories: state.categories, config: state.config
}))(withI18n()(withRouter(withApi(Home))))
