import React from 'react'
import { Route } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import ga from 'react-ga'

const trackingId = process.env.GA_TRACKING_ID

let tracker = null
if (trackingId) {
  tracker = ga
  ga.initialize(trackingId)
}

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => {
      if (trackingId) {
        ga.pageview(props.location.pathname)
      }
      return (
        <ErrorBoundary>
          <Component {...props} {...rest} ga={tracker} />
        </ErrorBoundary>
      )
    }} />
  )
}

export default PublicRoute
