export const APPLY = '@revolutility/APPLY_REVOLUVIP'
export const PUT_REWARD = '@revolutility/PUT_REVOLUVIP_REWARD'
export const APPLY_ERROR = '@revolutility/REVOLUVIP_APPLY_ERROR'
export const CLEAR = '@revolutility/REVOLUVIP_CLEAR'
export default ({
  apply: ({ email, password }) => ({ type: APPLY, params: { email, password } }),
  putReward: ({ reward }) => ({ type: PUT_REWARD, reward }),
  error: () => ({ type: APPLY_ERROR }),
  clear: () => ({ type: CLEAR })
})
