import '@babel/polyfill'
import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import './Index.scss'

ReactDOM.render(
  <App />,
  document.getElementById('app')
)
