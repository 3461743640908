import { FETCH_CATEGORIES, PUT_CATEGORIES } from 'actions/categories'
export default (
  state = {
    loading: false,
    data: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return Object.assign({}, state, { loading: true })
    case PUT_CATEGORIES:
      return Object.assign({}, state, { loading: false, data: action.categories })
    default:
      return state
  }
}
