import React from 'react'
import { NamespacesConsumer, Trans } from 'react-i18next'
import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['termsPrivacy']}>
    {t => {
      return (
        <div className={style.wrapper}>
          <section>
            <h1>
              <Trans i18nKey='title'>
                Committed to your data, committed to your privacy
              </Trans>
            </h1>
            <p>
              <Trans i18nKey='intro'>
                We are aware that you are worried about how your data is used. Therefore, REVOLUPAY is governed by the highest standards of respect for the fundamental right to the protection of personal data , established in the Charter of Fundamental Rights of the European Union, pursuing the preservation of the confidentiality of our clients' information. , as an essential pillar on which one of our core corporate values is based, trust. Now , we take a step further and reinforce our commitment to your privacy, by guaranteeing the treatment of your data in a loyal, transparent and responsible manner, and incorporate the principles included in Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016 (hereinafter, the General Data Protection Regulation) into our internal rules and procedures . This new standard homogenises in the European sphere the protection of people with regard to the treatment and free circulation of their data and establishes a framework of information and protection, in which we want you to feel comfortable. Because we are a company with values, which works with the aim of generating trust in your environment, under the criteria of proximity and transparency, always seeking to provide the best service to your customers. And that is why we explain how we treat your personal data.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('01 WHO ARE WE?')}</h1>
            <p>
              <Trans i18nKey='content-01'>
                RevoluPay is a technological platform specialized in the sale of travel and other online services of the companies of the RevoluGROUP Canada Inc. and other associated companies, as well as the management of payments between them and between them and their users.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('02 WHY DO WE NEED TO USE YOUR DATA?')}</h1>
            <h2>{t('Uses with contractual purpose')}</h2>
            <p>
              <Trans i18nKey='content-02-01'>
                The first and main reason why we need to process your data is for the provision of the services you have contracted with us and for our own management. This treatment is essential. If we did not, we could not manage your accounts and other services.
              </Trans>
            </p>
            <h2>{t('Uses for legal or regulatory purposes')}</h2>
            <p>
              <Trans i18nKey='content-02-02'>
                In REVOLUVIP, we are obliged by different regulations to treat your data to comply with the obligations that they have. These are standards that establish us, for example, regulatory reporting obligations, measures to prevent money laundering and financing of terrorism or tax controls and reports. In these cases, the treatment that is made of the data is limited to what is necessary to comply with those obligations or responsibilities legally required.
              </Trans>
            </p>
            <h2>{t('Uses for the purpose of preventing fraud')}</h2>
            <p>
              <Trans i18nKey='content-02-03'>
                We also need to treat your data for the prevention of fraud, as well as to ensure the security of both your information and our networks and information systems. As you may have seen, these three types of treatments are essential to maintain your relationship with US. Without them we could not lend you our services
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('03 AND WILL MY DATA NOT BE USED WITH MORE PURPOSES?')}</h1>
            <p>
              <Trans i18nKey='content-03-01'>
                The above uses are necessary to provide our services but, with your confidence, we would like to offer you much more.
              </Trans>
            </p>
            <h2>{t('Uses for commercial purposes based on legitimate interest')}</h2>
            <p>
              <Trans i18nKey='content-03-02'>
                Unless you have told us, or tell us otherwise, we will send you updates and information about products or services similar to those that you already have contracted.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-03-03'>
                We will also use your information (account movements, etc.) to personalize your experience with others, for example by showing you your most usual operations first; to offer you the products and services that fit your profile and not bother you with what you do not care about; to apply the benefits and promotions that we have in force and to which you are entitled, because we do not want you to miss any of the advantages of being our client.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-03-03'>
                NDo not worry. In these treatments we will not use more information than you have given us or that generated by the products contracted during the last year and, if you prefer that we do not, you just have to tell us, in any of our offices, or in the electronic address mis.datos@revolupay.es, or in our mobile applications. For any other commercial use that we want to do, we will ask you before, as you will see below. Remember that one of our core values is trust.
              </Trans>
            </p>
          </section>
          <section>
            <h1>
              <Trans i18nKey='title-04'>
                04 WE CAN NOT HIDE YOU: WE WILL KNOW YOU BETTER!
              </Trans>
            </h1>
            <p>
              <Trans i18nKey='content-04-01'>
                Nowadays, there are many possibilities to use the information to get to know you better, give you a better service, be more attentive and always ready to attend to your needs. Therefore, we will ask you for authorization to treat your data a little more than what we told you before. If you have already tried it, or try it in the future, you will not regret it, but do not worry, you do not have to decide now, we will ask you about it in the electronic channels .
              </Trans>
            </p>
            <h2>{t('Uses based on your consent ')}</h2>
            <p>
              <Trans i18nKey='content-04-02'>
                Only if you authorize us when we ask you, we would like to treat all the information we have about you to know you better, that is, study your needs to know what new products and services are adjusted to your preferences and analyze the information that allows us have determined in advance what your credit capacity is .
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-04-03'>
                We would also send you product offers from all Group companies and third parties that we think may interest you.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('05 WHAT HAPPENS WITH MY DATA WHEN I NAVIGATE THE WEB PAGES OR MOBILE APPLICATIONS OF REVOLUPAY?')}</h1>
            <p>
              <Trans i18nKey='content-05-01'>
                When you browse our web pages or use our mobile applications, we want to be able to personalize your experience so that it is as exceptional as possible. It is also possible that we want to remind you of our products and offers when you are surfing the internet .
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-02'>
                You already know that cookies are used for that. We will inform you at all times of the details of its use in the Cookies Policy, which you will find in all our web pages, as well as in the conditions of use of the mobile applications that you downloads.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-03'>
                There we will describe at each moment what data we can collect, how and for what they are used. In addition , most web browsers allow you to manage your preferences on the use of cookies. Remember that you can adjust at any time the navigator to reject or delete certain cookies according to your criteria.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-05-04'>
                Also, the privacy settings of the mobile device allow you to manage the processing of your data.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('06 BY CERTAIN, WHAT MINE DATA ARE TREATED?')}</h1>
            <p>
              <Trans i18nKey='content-06-01'>
                As you can imagine, thanks to the trust you have placed in us, we have a lot of information about you. We have already told you what we use them for and how you can control these uses at any time, but what concrete information are we going to try?
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-06-02'>
                Basically, it is your identification and detail of the professional or work activity, your contact data and financial and socioeconomic data, both those you have provided us and those that are generated from the products or services contracted.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-06-03'>
                Also, only if you consent when we consult you, we can process data we obtain from the provision of services to third parties when you are the recipient of the service, those obtained from the social networks that you authorize us to consult, the obtained from third parties such as result of data aggregation services that you request, those obtained from the navigations you make through our website, mobile telephony applications and other web pages of REVOLUPAY companies or those obtained from commercial information providers.
              </Trans>
            </p>
          </section>
          <section>
            <h1><Trans i18nKey='title-07'>07 ARE HEALTH, IDEOLOGY OR OTHER SPECIAL OR SENSITIVE DATA TREATED ?</Trans></h1>
            <p>
              <Trans i18nKey='content-07-01'>
                In general, we do not need to treat certain data of yours that are considered as special categories of data, for example those related to ethnic or racial origin, political opinions, religious convictions or sexual identity. If it is necessary to treat this type of sensitive data, in each case we will request an explicit consent.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('08 ARE MY DATA SECURE?')}</h1>
            <p>
              <Trans i18nKey='content-08-01'>
                For us the security of your data is essential, and we assume the obligation and commitment to protect them at all times . Therefore, within this maximum protection standard, we protect them against unauthorized or illicit treatments and against their loss, destruction or accidental damage, and we have implemented the most rigorous information security protocols following the best practices in this area.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('09 HOW LONG DO I NEED TO SAVE MY DATA?')}</h1>
            <p>
              <Trans i18nKey='content-09-01'>
                We will treat your data as long as the authorization of use that you have granted us remains valid or you have not canceled the contractual or business relationship with us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-09-02'>
                We will stop treating them once the authorizations of use that you have given us have been revoked or, if you have not revoked the authorizations but you have ceased to be a client, after six months from the end of the contractual or established business relationships, as long as your data is not necessary for the purposes for which they were collected or treated.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content-09-03'>
                This does not mean that we delete them immediately, since we are obliged by different rules to keep the information for some time (in many cases up to ten years), but according to the regulations, your data will only be kept to comply with these legal obligations, and for the formulation, exercise or defense of claims, during the limitation period of the actions derived from the contractual or business relationships subscribed.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('10 WHO DO MY DATA COME TO ?')}</h1>
            <p>
              <Trans i18nKey='content10-01'>
                In addition to the exchange of commercial information between the companies of the REVOLUPAY Group (of which we previously informed you), on certain occasions we need to share certain information with third parties in order to provide our services , either because a regulation requires them, or because we need the support from specialist companies to help us in our work.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content10-02'>
                Below we explain to you who we can share your information with, always with maximum security and confidentiality:
              </Trans>
            </p>
            <h2>{t('Communication of data for compliance with a legal obligation')}</h2>
            <p>
              <Trans i18nKey='content10-03'>
                As we have explained, we collaborate with the authorities, courts and public bodies. If the regulation establishes it, we will share with them the information that they offer us .
              </Trans>
            </p>
            <h2>{t('Communication of data for the execution of a contractual relationship ')}</h2>
            <p>
              <Trans i18nKey='content10-04'>
                Sometimes, we turn to service providers with potential access to personal data. These providers provide adequate and sufficient guarantees in relation to data processing, since we carry out a responsible selection of service providers that incorporate specific requirements in the event that the services involve the processing of personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content10-05'>
                We may also share your data with third parties that are necessary for the development, compliance and control of the contracts of products and services that you have subscribed with us.
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('11 ARE MY DATA TRANSFERRED OUTSIDE THE EUROPEAN ECONOMIC AREA?')}</h1>
            <p>
              <Trans i18nKey='content11-01'>
                The treatment of your data is done, in general, by service providers located within the European Economic Area or in countries that have been declared with an adequate level of protection.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content11-02'>
                In other cases we guarantee the safety and legitimacy of treatm ent of your data requiring suppliers that have binding corporate rules that guarantee the protection of information similarly to those established European standards, which are covered by the Privacy Shield , in the case of service providers in the USA. UU., Or that they subscribe to the standard clauses of the European Union.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content11-03'>
                At all times we will ensure that, whoever has your information to help us provide our services, does so with all the guarantees .
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('12 DO REVOLUPAY AND THE COMPANIES OF ITS GROUP HAVE A DELEGATE FOR DATA PROTECTION?')}</h1>
            <p>
              <Trans i18nKey='content12-01'>
                Indeed, as required by the data protection regulations, the companies of the REVOLUPAY Group have a Data Protection delegate who ensures that all the treatments carried out are done with total respect for your privacy and the applicable regulations at all times.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='content12-02'>
                The Data Protection delegate is at your disposal to answer all the questions you may have regarding the processing of your personal data and the exercise of your rights. You can contact the delegate of Data Protection at: delegado.datos@revolupay.es
              </Trans>
            </p>
          </section>
          <section>
            <h1>{t('13 WHAT RIGHTS DO I HAVE IN RELATION TO MY DATA AND ITS TREATMENTS?')}</h1>
            <p>
              <Trans i18nKey='content13-01'>
                These are the rights you can exercise in relation to your data:
              </Trans>
            </p>
            <ul>
              <li><span className={style.strong}>{t('Right of access')}</span> <span>{t('Right to know what data of yours we are dealing with')}</span></li>
              <li><span className={style.strong}>{t('Law of revocation of consent')}</span> <span>{t('Right to withdraw consent at any time when you have given us authorization to process your data')}</span></li>
              <li><span className={style.strong}>{t('Right of rectification')}</span> <span>{t('Right to rectify or complete your data if they are inaccurate')}</span></li>
              <li><span className={style.strong}>{t('Right of opposition')}</span> <span>{t('Right to oppose those treatments based on legitimate interest')}</span></li>
              <li><span className={style.strong}>{t('Right of suppression')}</span> <span>{t('Right to delete your data when it is no longer necessary for the purposes for which it was collected')}</span></li>
              <li><span className={style.strong}>{t('Right of limitation')}</span> <span>{t('Right to limit the treatment of your data (in certain cases, expressly provided in the regulations)')}</span></li>
              <li><span className={style.strong}>{t('Portability right')}</span> <span>{t('Right to give you your data (data processed for the execution of products and services and data that we deal with your consent) so you can transmit them to another person in charge')}</span></li>
            </ul>
            <p>
              <Trans i18nKey='content13-02'>
                You can exercise your rights in any of the channels that we put at your disposal:
              </Trans>
            </p>
            <ul>
              <li>{t('Through the options enabled for this purpose in our mobile applications ')}</li>
              <li>{t('At the electronic address')} mis.datos@revolupay.es</li>
            </ul>
          </section>
        </div>
      )
    }}
  </NamespacesConsumer>
)
