import React from 'react'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import { languages } from 'i18n'
import { Link } from 'react-router-dom'

import logo from '/../resources/img/logo.png'
import lang from '/../resources/img/language.svg'
import style from './Header.scss'

class Header extends React.Component {
  constructor ({ i18n }) {
    super()
    this.i18n = i18n
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.state = { language: 'en' }
  }

  componentWillMount () {
    this.i18n.on('languageChanged', this.handleLanguageChange)
  }

  componentWillUnmount () {
    this.i18n.off('languageChanged', this.handleLanguageChange)
  }

  handleLanguageChange (lng) {
    let language
    if (lng && lng.includes('es')) {
      language = 'es'
    } else {
      language = 'en'
    }
    this.setState({ language })
  }

  render () {
    return (
      <NamespacesConsumer ns={['home']}>
        {(t, { i18n }) => (
          <div className={style.wrapper}>
            <div className={style.container}>
              <div className={style.left}>
                <Link to='/'><img src={logo} /></Link>
              </div>
              <div className={style.right}>
                <Link to='/products'>{t('BROWSE_PRODUCTS')}</Link>
                <img src={lang} />
                <select value={this.state.language} onChange={(el) => {
                  i18n.changeLanguage(el.target.value)
                }}>
                  {languages.map(language => {
                    const value = Object.keys(language)[0]
                    const label = language[value]
                    return (<option key={value} value={value}>{t(label)}</option>)
                  })}
                </select>
              </div>
            </div>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
export default withI18n()(Header)
