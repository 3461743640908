import { call, put, takeLatest } from 'redux-saga/effects'
import orderActions, { PLACE } from 'actions/orders'

export default ({ api }) => {
  function * place (action) {
    console.log(action)
    try {
      const result = yield call(api.placeOrder, action.params)
      yield put(orderActions.put(result))
    } catch (error) {
      console.error(error)
      yield put(orderActions.error())
    }
  }

  return function * watch () {
    yield takeLatest(PLACE, place)
  }
}
