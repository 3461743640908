import { ADD_PRODUCT, CLEAR } from 'actions/shoppingCart'
export default (
  state = {
    product: null,
    receiveValue: null,
    fields: null
  },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return Object.assign(
        {},
        state,
        {
          product: action.product,
          receiveValue: action.receiveValue,
          fields: action.fields
        }
      )
    case CLEAR:
      return Object.assign(
        {},
        state,
        {
          product: null,
          receiveValue: null,
          fields: null
        }
      )
    default:
      return state
  }
}
