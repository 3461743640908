import { all, fork } from 'redux-saga/effects'
import currenciesSaga from 'sagas/currenciesSaga'
import countriesSaga from 'sagas/countriesSaga'
import configSaga from 'sagas/configSaga'
import categoriesSaga from 'sagas/categoriesSaga'
import productsSaga from 'sagas/productsSaga'
import revoluvipSaga from 'sagas/revoluvipSaga'
import orderSaga from 'sagas/orderSaga'
import paymentSaga from 'sagas/paymentSaga'

export default ({ api }) => function * root () {
  yield all([
    fork(currenciesSaga({ api })),
    fork(countriesSaga({ api })),
    fork(configSaga({ api })),
    fork(categoriesSaga({ api })),
    fork(productsSaga({ api })),
    fork(revoluvipSaga({ api })),
    fork(orderSaga({ api })),
    fork(paymentSaga({ api }))
  ])
}
