import { hot } from 'react-hot-loader'
import React from 'react'
import { BrowserRouter, Switch, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import PublicRoute from 'components/main/PublicRoute'
import style from './Main.scss'

import currencyActions from 'actions/currencies'
import countryActions from 'actions/countries'
import configActions from 'actions/config'
import categoryActions from 'actions/categories'

import Header from 'components/main/header/Header'
import Footer from 'components/main/footer/Footer'

import Home from 'components/home/Home'
import Products from 'components/products/Products'
import Checkout from 'components/checkout/Checkout'
import Payment from 'components/payment/Payment'
import PaymentSuccess from 'components/payment/PaymentSuccess'
import PaymentError from 'components/payment/PaymentError'

import Company from 'components/terms/Company'
import Cookies from 'components/terms/Cookies'
import Legal from 'components/terms/Legal'
import Privacy from 'components/terms/Privacy'
import Licenses from 'components/terms/Licenses'

import CookieBanner from 'react-cookie-banner'

import ScrollToTop from './ScrollToTop'

class Main extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentWillMount () {
    this.dispatch(currencyActions.fetch())
    this.dispatch(configActions.fetch())
    this.dispatch(countryActions.fetch())
    this.dispatch(categoryActions.fetch())
  }

  render () {
    return (
      <NamespacesConsumer ns={['home']}>
        {t => (
          <BrowserRouter>
            <div className={style.wrapper}>
              <ScrollToTop />
              <Header />
              <CookieBanner
                className={style.cookiebanner}
                message={t('COOKIES')}
                link={<Link to='/cookies'>{t('COOKIE_DETAILS')}</Link>}
                buttonMessage={t('Ok')}
                cookie='user-has-accepted-cookies'
              />
              <div id='scrollableContainer' className={style.container} >
                <div className={style.main}>
                  <Switch>
                    <PublicRoute exact path='/' component={Home} />
                    <PublicRoute exact path='/products' component={Products} />
                    <PublicRoute exact path='/checkout' component={Checkout} />
                    <PublicRoute exact path='/payment' component={Payment} />
                    <PublicRoute exact path='/payment/success' component={PaymentSuccess} />
                    <PublicRoute exact path='/payment/error' component={PaymentError} />
                    <PublicRoute exact path='/company' component={Company} />
                    <PublicRoute exact path='/cookies' component={Cookies} />
                    <PublicRoute exact path='/legal' component={Legal} />
                    <PublicRoute exact path='/privacy' component={Privacy} />
                    <PublicRoute exact path='/licenses' component={Licenses} />
                    <Redirect to='/' />
                  </Switch>
                </div>
                <Footer />
              </div>
            </div>
          </BrowserRouter>
        )}
      </NamespacesConsumer>
    )
  }
}

let component
if (process.env.NODE_ENV === 'development') {
  component = hot(module)(connect()(Main))
} else {
  component = connect()(Main)
}
export default component
