export const FETCH = '@revolutility/FETCH_CONFIG'
export const PUT_CONFIG = '@revolutility/PUT_CONFIG'
export default ({
  fetch: () => ({
    type: FETCH
  }),
  put: (config) => ({
    type: PUT_CONFIG,
    config
  })
})
