export const FETCH_CURRENCIES = '@revolutility/FETCH_CURRENCIES'
export const PUT_CURRENCIES = '@revolutility/PUT_CURRENCIES'
export default ({
  fetch: () => ({
    type: FETCH_CURRENCIES
  }),
  put: (currencies) => ({
    type: PUT_CURRENCIES,
    currencies
  })
})
