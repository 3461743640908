export const FETCH_CATEGORIES = '@revolutility/FETCH_CATEGORIES'
export const PUT_CATEGORIES = '@revolutility/PUT_CATEGORIES'

export default ({
  fetch: () => ({
    type: FETCH_CATEGORIES
  }),
  put: (categories) => ({
    type: PUT_CATEGORIES,
    categories
  })
})
