import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './Products.scss'
import productActions from 'actions/products'
import Searchbar from '../shared/Searchbar'
import CurrencyFormat from '../shared/CurrencyFormat'
import queryString from 'query-string'
import noResultsImg from '/../resources/img/not-found.svg'
import Spinner from 'react-spinkit'
import Select from 'react-select'
import classNames from 'classnames'
import { round } from 'services/Functions'

import ProductSelectionModal from './ProductSelectionModal'

class Products extends React.Component {
  constructor (props) {
    super()
    this.state = {
      selectedProduct: null,
      shouldShowProductModal: false,
      userSelectedCountry: false,
      userSelectedCategory: false,
      selectedCountry: { value: null, label: 'ALL_COUNTRIES' },
      selectedCategory: { id: null, alias: 'ALL_CATEGORIES' }
    }
  }

  static getDerivedStateFromProps (props, state) {
    const query = queryString.parse(props.location.search)
    if (
      !state.userSelectedCategory &&
      query.categoryId &&
      state.selectedCategory.id === null &&
      props.categories.data.length > 0
    ) {
      const filteredCategory = props.categories.data.filter(c => c.id === parseInt(query.categoryId, 10))
      if (filteredCategory.length > 0) {
        state.selectedCategory = filteredCategory[0]
      }
    }
    if (query.countryId && state.selectedCountry.value === null && props.countries.data.length > 0) {
      const filteredCountry = props.countries.data.filter(c => c.id === parseInt(query.countryId, 10))
      if (filteredCountry.length > 0) {
        state.selectedCountry = { value: filteredCountry[0].id, label: filteredCountry[0].name }
      }
    }
    if (
      !state.userSelectedCountry &&
      state.selectedCountry.value === null &&
      !query.countryId &&
      props.countries.userCountry
    ) {
      state.selectedCountry = {
        value: props.countries.userCountry.id,
        label: props.countries.userCountry.name
      }
      query.countryId = props.countries.userCountry.id
      props.dispatch(productActions.fetch(query))
    }
    return state
  }

  componentDidMount () {
    const query = queryString.parse(this.props.location.search)
    if (query.countryId === 'null') {
      delete query.countryId
    } else if (!query.countryId && this.props.countries.userCountry) {
      query.countryId = this.props.countries.userCountry.id
    }
    this.props.dispatch(productActions.fetch(query))
  }

  fetchMoreData = (e) => {
    e.preventDefault()
    this.props.dispatch(
      productActions.fetch({
        offset: this.props.products.data.elements.length,
        name: this.props.products.params.name,
        categoryId: this.props.products.params.categoryId,
        countryId: this.props.products.params.countryId
      })
    )
  }

  selectCountry = selectedCountry => {
    this.setState({ selectedCountry, userSelectedCountry: true })
    this.filter({ countryId: selectedCountry.value })
    if (this.props.ga) {
      this.props.ga.event({
        category: 'Product Filter',
        action: 'Selected country',
        label: selectedCountry.label
      })
    }
  }

  selectCategory = selectedCategory => {
    this.setState({ selectedCategory, userSelectedCategory: true })
    this.filter({ categoryId: selectedCategory.id })
    if (this.props.ga) {
      this.props.ga.event({
        category: 'Product Filter',
        action: 'Selected category',
        label: selectedCategory.alias
      })
    }
  }

  selectProduct = (event, product) => {
    if (event.target.className === style.promotionDetails) return
    this.setState({ selectedProduct: product, shouldShowProductModal: true })
    if (this.props.ga) {
      this.props.ga.event({
        category: 'Product Selection',
        action: 'Clicked on product',
        label: product.product.name
      })
    }
  }

  onPromotionDetail = (product) => {
    console.log(product)
  }

  filter = ({ name, categoryId, countryId }) => {
    const request = { name, categoryId, countryId }
    if (name || name === '') {
      request.categoryId = this.props.products.params.categoryId
      request.countryId = this.props.products.params.countryId
    } else if (categoryId || categoryId === null) {
      request.name = this.props.products.params.name
      request.countryId = this.props.products.params.countryId
    } else if (countryId || countryId === null) {
      request.name = this.props.products.params.name
      request.categoryId = this.props.products.params.categoryId
    }
    this.props.dispatch(productActions.fetch(request))
    const currentUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
    const newUrl = `${currentUrl}?${queryString.stringify(request)}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  render () {
    return (
      <NamespacesConsumer ns={['products', 'categories', 'countries']}>
        {t => {
          const query = queryString.parse(this.props.location.search)
          let countries = this.props.countries.data.map(c => ({
            value: c.id,
            label: t(`countries:${c.name}`)
          }))
          countries = [{ value: null, label: t('countries:ALL_COUNTRIES') }, ...countries]
          const selectedCountry = {
            value: this.state.selectedCountry.value,
            label: t(`countries:${this.state.selectedCountry.label}`)
          }

          const getCountries = (p) => {
            if (p.countries.length > 10) {
              return (
                <div className={style.countriesContainer}>
                  {t('MORE_THAN_10_COUNTRIES')}
                </div>
              )
            } else {
              return (
                <div className={style.countriesContainer}>
                  {p.countries.map(c => (
                    <React.Fragment key={c.id}>
                      {c.name}{p.countries.indexOf(c) === p.countries.length - 1 ? '' : ', '}
                    </React.Fragment>
                  ))}
                </div>
              )
            }
          }

          const categories = [{ id: null, alias: 'ALL_PRODUCTS' }, ...this.props.categories.data]
          return (
            <div className={style.wrapper}>
              <ProductSelectionModal
                open={this.state.shouldShowProductModal}
                product={this.state.selectedProduct}
                onClose={() => { this.setState({ shouldShowProductModal: false, selectedProduct: null }) }}
                onSubmit={() => {
                  this.props.history.push('/checkout')
                  if (this.props.ga) {
                    this.props.ga.event({
                      category: 'Product selection',
                      action: 'Product checkout',
                      label: this.state.selectedProduct.product.name
                    })
                  }
                }}
              />
              <div className={style.left}>
                <div className={style.countrySelector}>
                  <Select
                    value={selectedCountry}
                    onChange={this.selectCountry}
                    options={countries}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#D61C6A',
                        primary25: '#f8befc'
                      }
                    })}
                  />
                </div>
                <div className={style.categoryContainer}>
                  {categories.map(category => (
                    <div
                      className={classNames(
                        style.category,
                        {
                          [style.selected]:
                            this.state.selectedCategory &&
                            category.id === this.state.selectedCategory.id
                        }
                      )}
                      key={category.id}
                      onClick={() => { this.selectCategory(category) }}
                    >
                      {category.icon &&
                        <img src={`${process.env.ASSETS}/${category.icon}`} />
                      }
                      <span>{t(`categories:${category.alias}`)}</span>
                    </div>
                  ))
                  }
                </div>
              </div>
              <div className={style.right}>
                <Searchbar
                  currentProduct={query.name}
                  action={({ product }) => {
                    this.filter({ name: product })
                    if (this.props.ga) {
                      this.props.ga.event({
                        category: 'Product Filter',
                        action: 'Searched product',
                        label: product
                      })
                    }
                  }}
                />
                <div className={style.productsContainer}>
                  {this.props.products.data.elements.map(p => (
                    <div onClick={(e) => this.selectProduct(e, p)} className={style.product} key={p.product.id}>
                      <img className={style.logo} src={`${process.env.ASSETS}/${p.product.logo}`} />
                      <div className={style.details}>
                        <span className={style.name}>
                          {p.product.name}
                        </span>
                        {p.promotions.length > 0 &&
                          <span className={style.promotion} onClick={() => this.onPromotionDetail(p)}>
                            {t('PRODUCT_UNDER_PROMOTION')}
                          </span>
                        }
                        {p.product.minimumReceiveAmount === p.product.maximumReceiveAmount &&
                          <React.Fragment>
                            {p.receiveCurrency.iso !== '000' &&
                              <span className={style.amount}>
                                <CurrencyFormat
                                  value={p.product.minimumReceiveAmount}
                                  currencyIso={p.receiveCurrency.iso} />
                              </span>
                            }
                            <span className={style.cost}>
                              <CurrencyFormat
                                value={round(p.product.minimumSendAmount / p.sendCurrency.rate)}
                                currencyIso={'₡CU'} />
                            </span>
                          </React.Fragment>
                        }
                        {p.product.minimumReceiveAmount !== p.product.maximumReceiveAmount &&
                          <React.Fragment>
                            <span className={style.amount}>
                              <CurrencyFormat
                                value={p.product.minimumReceiveAmount}
                                currencyIso={p.receiveCurrency.iso} />
                              -
                              <CurrencyFormat
                                value={p.product.maximumReceiveAmount}
                                currencyIso={p.receiveCurrency.iso} />
                            </span>
                            <span className={style.cost}>
                              <CurrencyFormat
                                value={round(p.product.minimumSendAmount / p.sendCurrency.rate)}
                                currencyIso={'₡CU'} />
                              -
                              <CurrencyFormat
                                value={round(p.product.maximumSendAmount / p.sendCurrency.rate)}
                                currencyIso={'₡CU'} />
                            </span>
                          </React.Fragment>
                        }
                        <span className={style.description}>
                          {p.product.description}
                        </span>
                        {this.state.selectedCountry && this.state.selectedCountry.value === null &&
                          getCountries(p)
                        }
                      </div>

                    </div>
                  ))}
                  {this.props.products.loading &&
                    <div className={style.spinner} >
                      <Spinner name='ball-scale-multiple' fadeIn='quarter' />
                    </div>
                  }
                  {this.props.products.data.elements.length === 0 && !this.props.products.loading &&
                    <div className={style.noResults}>
                      <img src={noResultsImg} />
                      <span>{t('NO_RESULTS')}</span>
                    </div>
                  }
                  {this.props.products.data.count > this.props.products.data.elements.length && !this.props.products.loading &&
                    <a href='#' onClick={this.fetchMoreData}>{t('LOAD_MORE')}</a>
                  }
                </div>
              </div>
            </div>
          )
        }
        }
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({
  categories: state.categories, countries: state.countries, products: state.products, config: state.config
}))(withRouter(Products))
