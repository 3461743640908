export default ({ baseUrl, fetch }) => ({
  getCurrencies: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/currencies`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getBestsellers: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/products/bestsellers`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getCategories: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/categories`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getCountries: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/countries`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getConfig: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/config`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getProducts: ({
    limit = 10,
    offset = 0,
    name = null,
    categoryId = null,
    countryId = null
  }) => new Promise((resolve, reject) => {
    const params = new URLSearchParams()
    params.append('limit', limit)
    params.append('offset', offset)
    if (name) params.append('name', name)
    if (categoryId) params.append('categoryId', categoryId)
    if (countryId) params.append('countryId', countryId)
    fetch(
      `${baseUrl}/products?${params.toString()}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  geoip: () => new Promise((resolve, reject) => {
    fetch(
      `https://geoip-db.com/json/`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  applyRevoluvip: ({ email, password }) => new Promise((resolve, reject) => {
    const params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    fetch(
      `${baseUrl}/revoluvip?${params.toString()}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  placeOrder: ({ receiveValue, productId, locale = 'en', fields = null, revoluvip = null }) => new Promise((resolve, reject) => {
    let filteredLocale
    if (locale.includes('es')) {
      filteredLocale = 'es'
    } else {
      filteredLocale = 'en'
    }
    const request = {
      locale: filteredLocale,
      receiveValue,
      productId
    }
    if (fields && Object.keys(fields).length > 0) {
      request.fields = Object.keys(fields).map(key => ({ name: key, value: fields[key] }))
    }
    if (revoluvip && Object.keys(revoluvip).length > 0) {
      request.revoluvip = revoluvip
    }
    fetch(
      `${baseUrl}/orders`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(request)
      }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),

  paymentStatus: ({ id }) => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/payments/revolupay/${id}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  })
})
