import React from 'react'
import style from './Payment.scss'
import { Link } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'
import successImg from '/../resources/img/success.svg'

export default () => (
  <NamespacesConsumer ns={['payment']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.content}>
          <h1>{t('PAYMENT_COMPLETED')}</h1>
          <h2>{t('PAYMENT_COMPLETED_DETAILS')}</h2>
          <div className={style.payment}>
            <img src={successImg} />
          </div>
          <h3>{t('PAYMENT_COMPLETION_INSTRUCTIONS')}</h3>
          <Link to='/products'>{t('NEW_ORDER')}</Link>
        </div>
      </div>
    )}
  </NamespacesConsumer>
)
