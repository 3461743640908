import { call, put, takeLatest } from 'redux-saga/effects'
import countryActions, { FETCH_COUNTRIES } from 'actions/countries'

export default ({ api }) => {
  function * fetchCountries () {
    try {
      const countries = yield call(api.getCountries)
      let userCountry = null
      try {
        const geoIp = yield call(api.geoip)
        const filteredCountries = countries.filter(c => c.iso2 === geoIp.country_code)
        if (filteredCountries.length > 0) {
          userCountry = filteredCountries[0]
        }
      } catch (e) {
        console.error(e)
      }
      yield put(countryActions.put({ countries, userCountry }))
    } catch (error) {
      console.error(error)
      yield put(countryActions.put({ countries: [], userCountry: null }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_COUNTRIES, fetchCountries)
  }
}
