export const FETCH_PRODUCTS = '@revolutility/FETCH_PRODUCTS'
export const PUT_PRODUCTS = '@revolutility/PUT_PRODUCTS'

export default ({
  fetch: (params) => ({
    type: FETCH_PRODUCTS,
    params
  }),
  put: (response) => ({
    type: PUT_PRODUCTS,
    response
  })
})
