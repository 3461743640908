import React from 'react'
import style from './ProductSelectionModal.scss'
import Modal from 'react-responsive-modal'
import CurrencyFormat from '../shared/CurrencyFormat'
import { round } from '../../services/Functions'
import classNames from 'classnames'
import { NamespacesConsumer } from 'react-i18next'
import changeCase from 'change-case'
import shoppingCartActions from 'actions/shoppingCart'
import { connect } from 'react-redux'

class ProductSelectionModal extends React.Component {
  initialState = {
    fieldErrors: {}, fields: {}, receiveValue: '', sendValue: '', currentProduct: null
  }

  constructor () {
    super()
    this.state = Object.assign({}, this.initialState)
  }

  static getDerivedStateFromProps (props, state) {
    if (props.receiveValue && state.receiveValue === '') {
      state.receiveValue = props.receiveValue
      state.sendValue = state.receiveValue *
        props.product.product.maximumSendAmount /
        props.product.product.maximumReceiveAmount
    }
    if (props.fields && Object.keys(state.fields).length === 0) {
      state.fields = props.fields
    }
    if (props.product !== null && state.currentProduct === null && !state.closing) {
      if (props.product !== null && state.sendValue === '') {
        state.sendValue = props.product.product.minimumSendAmount
      }
      if (props.product !== null && state.receiveValue === '') {
        state.receiveValue = props.product.product.minimumReceiveAmount
      }
      if (Object.keys(state.fields).length === 0) {
        state.fields = props.product.product.fields.reduce((result, field) => {
          if (field.type === 'select') {
            result[field.name] = field.elements[0].value
          } else {
            result[field.name] = ''
          }
          return result
        }, {})
        state.fieldErrors = props.product.product.fields.reduce((result, field) => {
          result[field.name] = false
          return result
        }, {})
      }
      state.currentProduct = props.product
    }
    if (state.closing) {
      delete state.closing
    }
    return state
  }

  onClose = () => {
    this.setState(Object.assign({ closing: true }, this.initialState))
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  updateAmount = (event) => {
    const product = this.props.product.product
    let receiveValue = round(parseFloat(event.target.value))
    if (isNaN(receiveValue) || receiveValue < 0) {
      receiveValue = 0
    }
    const sendValue = receiveValue * product.maximumSendAmount / product.maximumReceiveAmount
    this.setState({ receiveValue: receiveValue, sendValue: round(sendValue) })
  }
  validateAmount = (event) => {
    const product = this.props.product.product
    let receiveValue = parseFloat(event.target.value)
    if (product.amountStep) {
      receiveValue = Math.round(receiveValue / product.amountStep) * product.amountStep
    }
    if (receiveValue < product.minimumReceiveAmount) {
      receiveValue = product.minimumReceiveAmount
    } else if (receiveValue > product.maximumReceiveAmount) {
      receiveValue = product.maximumReceiveAmount
    }
    const sendValue = receiveValue * product.maximumSendAmount / product.maximumReceiveAmount
    this.setState({ receiveValue: receiveValue, sendValue: round(sendValue) })
  }

  updateField = (event) => {
    const fieldName = event.target.id
    this.setState({ fields: { ...this.state.fields, [fieldName]: event.target.value } })
  }

  validateField = (event) => {
    const fieldName = event.target.id
    const fieldValue = event.target.value
    const field = this.props.product.product.fields.filter(f => f.name === fieldName)[0]
    if (field.type === 'input') {
      const match = field.pattern.match(new RegExp('^/(.*?)/([gimyu]*)$'))
      const regexp = new RegExp(match[1], match[2])
      if (fieldValue === '' && !field.required) {
        this.setState({ fieldErrors: { ...this.state.fieldErrors, [fieldName]: false } })
        return
      }
      if (!regexp.test(fieldValue)) {
        this.setState({ fieldErrors: { ...this.state.fieldErrors, [fieldName]: true } })
      } else {
        this.setState({ fieldErrors: { ...this.state.fieldErrors, [fieldName]: false } })
      }
    } else if (field.type === 'select') {
      const availableOptions = field.elements.map(e => e.value)
      if (!availableOptions.includes(fieldValue)) {
        this.setState({ fieldErrors: { ...this.state.fieldErrors, [fieldName]: true } })
      } else {
        this.setState({ fieldErrors: { ...this.state.fieldErrors, [fieldName]: false } })
      }
    }
  }

  submit = () => {
    const product = this.props.product.product
    let beneficiaryFieldsValid = true
    const fields = {}
    const fieldErrors = {}
    for (const field of product.fields) {
      const fieldValue = this.state.fields[field.name]
      if (field.type === 'input') {
        const match = field.pattern.match(new RegExp('^/(.*?)/([gimyu]*)$'))
        const regexp = new RegExp(match[1], match[2])
        if (fieldValue === '' && !field.required) {
          fieldErrors[field.name] = false
        } else {
          if (!regexp.test(fieldValue)) {
            beneficiaryFieldsValid = false
            fieldErrors[field.name] = true
          } else {
            fieldErrors[field.name] = false
          }
        }
      } else if (field.type === 'select') {
        const availableOptions = field.elements.map(e => e.value)
        if (!availableOptions.includes(fieldValue)) {
          fieldErrors[field.name] = true
          beneficiaryFieldsValid = false
        } else {
          fieldErrors[field.name] = false
        }
      }
      fields[field.name] = fieldValue
    }
    let receiveValue = this.state.receiveValue
    if (product.amountStep) {
      receiveValue = Math.round(receiveValue / product.amountStep) * product.amountStep
    }
    if (receiveValue < product.minimumReceiveAmount) {
      receiveValue = product.minimumReceiveAmount
    } else if (receiveValue > product.maximumReceiveAmount) {
      receiveValue = product.maximumReceiveAmount
    }
    const sendValue = receiveValue * product.maximumSendAmount / product.maximumReceiveAmount
    this.setState({ receiveValue: receiveValue, sendValue: round(sendValue), fields, fieldErrors })
    if (beneficiaryFieldsValid && this.props.onSubmit) {
      this.props.dispatch(shoppingCartActions.add({
        product: this.props.product,
        receiveValue: receiveValue,
        fields: Object.keys(fields).reduce((data, key) => {
          if (fields[key]) {
            data[key] = fields[key]
          }
          return data
        }, {})
      }))
      this.props.onSubmit()
    }
  }

  render = () => (
    <NamespacesConsumer ns={['products']}>
      {t => {
        const p = this.props.product
        if (!p) {
          return (<div />)
        }
        return (
          <Modal
            classNames={{ modal: style.modal }}
            open={this.props.open}
            onClose={this.onClose}>
            <div className={style.wrapper}>
              <div className={style.body}>
                <div className={style.logo}>
                  <img src={`${process.env.ASSETS}/${p.product.logo}`} />
                </div>
                <span className={style.name}>{p.product.name}</span>
                <span className={style.details}>{p.product.description}</span>
                <div className={style.countriesWrapper}>
                  <span className={style.label}>{t('AVAILABLE_COUNTRIES')}</span>
                  <div className={style.container}>
                    {p.countries.map(c => (
                      <span
                        className={p.countries.length > 1 ? style.multiple : style.single}
                        key={c.id}>
                        {c.name}{p.countries.indexOf(c) === p.countries.length - 1 ? '' : ', '}
                      </span>
                    ))}
                  </div>
                </div>
                {p.product.minimumReceiveAmount !== p.product.maximumReceiveAmount &&
                  <React.Fragment>
                    <div className={style.info}>
                      <span className={style.label}>{t('AVAILABLE_RECEIVE_VALUE')}</span>
                      <span className={style.value}>
                        <CurrencyFormat
                          value={p.product.minimumReceiveAmount}
                          currencyIso={p.receiveCurrency.iso} />
                        {` - `}
                        <CurrencyFormat
                          value={p.product.maximumReceiveAmount}
                          currencyIso={p.receiveCurrency.iso} />
                      </span>
                    </div>
                    <div className={style.formField}>
                      <span className={style.label}>{t('RECEIVE_VALUE')}</span>
                      <div
                        className={classNames({
                          [style.inputWrapper]: true, [style.error]: false
                        })}>
                        <div
                          className={style.inputControl}>
                          <input
                            type='number'
                            value={this.state.receiveValue}
                            onBlur={this.validateAmount}
                            onChange={this.updateAmount}
                          />
                          <span className={style.detail}>{p.receiveCurrency.iso}</span>
                        </div>
                        <span className={style.info}>{t('INPUT_RECEIVE_VALUE')}</span>
                      </div>
                    </div>
                  </React.Fragment>
                }
                <div className={style.infoWrapper}>
                  {p.product.minimumSendAmount === p.product.maximumSendAmount && p.receiveCurrency.iso !== '000' &&
                    <div className={style.info}>
                      <span className={style.label}>{t('RECEIVE_VALUE')}</span>
                      <span className={style.value}>
                        <CurrencyFormat
                          value={this.state.receiveValue}
                          currencyIso={p.receiveCurrency.iso} />
                      </span>
                    </div>
                  }
                  <div className={style.info}>
                    <span className={style.label}>{t('SEND_VALUE')}</span>
                    <span className={style.value}>
                      <CurrencyFormat
                        value={round(this.state.sendValue / p.sendCurrency.rate)}
                        currencyIso={'₡CU'} />
                    </span>
                  </div>
                </div>
                {p.product.fields.length > 0 &&
                  <div className={style.beneficiaryForm}>
                    <span className={style.beneficiaryFormLabel}>{t('BENEFICIARY_INFORMATION')}</span>
                    <div className={style.formFieldContainer}>
                      {p.product.fields.map(f => (
                        <div key={f.name} className={style.formField}>
                          <span className={style.label}>{changeCase.upperCaseFirst(t(f.name))}</span>
                          <div
                            className={classNames({
                              [style.inputWrapper]: true,
                              [style.error]: this.state.fieldErrors[f.name]
                            })}>
                            <div
                              className={style.inputControl}>
                              {f.type === 'input' &&

                                <input
                                  type='text'
                                  id={f.name}
                                  value={this.state.fields[f.name]}
                                  onBlur={this.validateField}
                                  onChange={this.updateField}
                                />
                              }
                              {f.type === 'select' &&

                                <select
                                  id={f.name}
                                  value={this.state.fields[f.name]}
                                  onBlur={this.validateField}
                                  onChange={this.updateField}
                                >
                                  {f.elements.map(e => (
                                    <option key={e.value} value={e.value}>{e.description}</option>
                                  ))}

                                </select>
                              }
                            </div>
                            {(() => {
                              if (this.state.fieldErrors[f.name]) {
                                return (
                                  <span className={style.info}>
                                    {t('BENEFICIARY_FIELD_ERROR')}
                                  </span>
                                )
                              } else {
                                return (
                                  <span className={style.info}>
                                    {(() => {
                                      if (f.name === 'mobile' || f.name === 'phone') {
                                        return (t('PHONE_HELP'))
                                      } else {
                                        return (f.description)
                                      }
                                    })()}
                                  </span>
                                )
                              }
                            })()}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </div>

              <div className={style.submit}>
                <button onClick={this.submit}>{t('SUBMIT')}</button>
              </div>
            </div>
          </Modal>
        )
      }}
    </NamespacesConsumer>
  )
}

export default connect(state => ({ shoppingCart: state.shoppingCart }))(ProductSelectionModal)
