import { PLACE, PUT, ERROR, CLEAR } from 'actions/orders'
export default (state = { loading: false, error: false, result: null }, action) => {
  switch (action.type) {
    case CLEAR: return { loading: false, error: false, result: null }
    case PLACE: return Object.assign({}, state, { loading: true, error: false })
    case PUT: return Object.assign({}, state, { loading: false, error: false, result: action.result })
    case ERROR: return Object.assign({}, state, { loading: false, error: true, result: null })
    default: return state
  }
}
