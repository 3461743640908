import { FETCH_PRODUCTS, PUT_PRODUCTS } from 'actions/products'
export default (
  state = {
    loading: false,
    params: {
      name: null,
      categoryId: null,
      countryId: null
    },
    data: {
      count: 0,
      elements: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      let data = state.data
      if (
        action.params.name !== state.params.name ||
        action.params.categoryId !== state.params.categoryId ||
        action.params.countryId !== state.params.countryId ||
        !action.params.offset ||
        action.params.offset === 0
      ) {
        data = {
          count: 0,
          elements: []
        }
      }
      return Object.assign(
        {},
        state,
        {
          loading: true,
          data,
          params: action.params
        }
      )
    case PUT_PRODUCTS:
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            count: action.response.count,
            elements: [...state.data.elements, ...action.response.elements]
          }
        }
      )
    default:
      return state
  }
}
