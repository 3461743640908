import { combineReducers } from 'redux'
import currenciesReducer from 'reducers/currenciesReducer'
import countriesReducer from 'reducers/countriesReducer'
import categoriesReducer from 'reducers/categoriesReducer'
import configReducer from 'reducers/configReducer'
import productsReducer from 'reducers/productsReducer'
import shoppingCartReducer from 'reducers/shoppingCartReducer'
import revoluvipReducer from 'reducers/revoluvipReducer'
import orderReducer from 'reducers/orderReducer'
import paymentReducer from 'reducers/paymentReducer'

export default combineReducers({
  currencies: currenciesReducer,
  countries: countriesReducer,
  categories: categoriesReducer,
  config: configReducer,
  products: productsReducer,
  shoppingCart: shoppingCartReducer,
  revoluvip: revoluvipReducer,
  order: orderReducer,
  payment: paymentReducer
})
