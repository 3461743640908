export const FETCH_COUNTRIES = '@revolutility/FETCH_COUNTRIES'
export const PUT_COUNTRIES = '@revolutility/PUT_COUNTRIES'
export default ({
  fetch: () => ({
    type: FETCH_COUNTRIES
  }),
  put: ({ countries, userCountry }) => ({
    type: PUT_COUNTRIES,
    countries,
    userCountry
  })
})
