export const ADD_PRODUCT = '@revolutility/ADD_PRODUCT_TO_CART'
export const CLEAR = '@revolutility/CLEAR_PRODUCTS_FROM_CART'

export default ({
  add: ({ product, receiveValue, fields }) => ({
    type: ADD_PRODUCT,
    product,
    receiveValue,
    fields
  }),
  clear: () => ({
    type: CLEAR
  })
})
