import { call, put, takeLatest } from 'redux-saga/effects'
import categoryActions, { FETCH_CATEGORIES } from 'actions/categories'

export default ({ api }) => {
  function * fetchCategories () {
    try {
      const categories = yield call(api.getCategories)
      yield put(categoryActions.put(categories))
    } catch (error) {
      console.error(error)
      yield put(categoryActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_CATEGORIES, fetchCategories)
  }
}
