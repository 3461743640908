import { call, put, takeLatest } from 'redux-saga/effects'
import paymentActions, { STATUS } from 'actions/payments'

export default ({ api }) => {
  function * status (action) {
    try {
      const result = yield call(api.paymentStatus, action.params)
      yield put(paymentActions.update(result))
    } catch (error) {
      console.error(error)
    }
  }

  return function * watch () {
    yield takeLatest(STATUS, status)
  }
}
