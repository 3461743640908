import { call, put, takeLatest } from 'redux-saga/effects'
import productActions, { FETCH_PRODUCTS } from 'actions/products'

export default ({ api }) => {
  function * fetchProducts (action) {
    try {
      const response = yield call(api.getProducts, action.params)
      yield put(productActions.put(response))
    } catch (error) {
      console.error(error)
      yield put(productActions.put({ elements: [], count: 0 }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_PRODUCTS, fetchProducts)
  }
}
