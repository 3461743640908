import React from 'react'
import { NamespacesConsumer, Trans } from 'react-i18next'
import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['termsCookies']}>
    {t => (
      <div className={style.wrapper}>
        <section>
          <h1>{t('Use of cookies')}</h1>
          <p>
            <Trans i18nKey='content01-01'>
              To optimize our services, this Site, messages sent by email, online services, advertisements and interactive applications may use "cookies"
            </Trans>
          </p>
        </section>
        <section>
          <h1>{t('What is a cookie?')}</h1>
          <p>
            <Trans i18nKey='content02-01'>
            A cookie is a file (small file) that is downloaded to your computer when accessing certain web pages, usually formed by letters and numbers, sent by the server of our website to the cookie file of your browser. Cookies allow our website, among other things, store and retrieve information about your browsing habits or your computer and remember your presence to establish a new connection between our server and your browser. The main purpose of a cookie is to allow our server to present personalized web pages that can convert the visit to the Web of the Web in an individual experience and adjusted to personal preferences.
            </Trans>
          </p>
        </section>
        <section>
          <h1>{t('What kind of cookies does this Site use?')}</h1>
          <h2><Trans i18nKey='title2-01'>1.) The Web uses two types of cookies in this Site according to the time of permanence:</Trans></h2>
          <ul>
            <li><Trans i18nKey='content-02-02'>Session Cookies: these are temporary cookies that remain in the cookie file of your browser while you are on our website. These cookies are necessary for certain applications or functions and allow this Site to function properly.</Trans></li>
            <li><Trans i18nKey='content-02-03'>Persistent cookies: favor the user experience (for example, offering personalized navigation). These cookies remain in your browser for a longer period of time. This period depends on the settings that you have entered in your browser. Persistent cookies allow information to be transferred to the server of this Site each time you visit it. They are also known as tracking cookies.</Trans></li>
          </ul>
          <h2><Trans i18nKey='title2-02'>2.) According to the purpose for which the data obtained is processed, this Site uses the following types of cookies:</Trans></h2>
          <ul>
            <li><Trans i18nKey='content-02-04'>Technical cookies: are those that allow navigation through a web page, platform or application and the use of different options or services that exist in it, such as traffic control and data communication, identify the session, access restricted access parts, remember the elements that make up an order, perform the purchase process of an order, make the request for registration or participation in an event, use security elements during navigation, store contents for dissemination of videos or sound or share content through social networks.</Trans></li>
            <li><Trans i18nKey='content-02-05'>Personalization cookies: These are the ones that allow you to access the service with some predefined general characteristics based on a series of criteria in your terminal (for example, the language, the type of browser through which you access the service, the configuration regional from where you access the service, etc).</Trans></li>
            <li><Trans i18nKey='content-02-06'>Analysis Cookies: Those that are well treated by us or by third parties, allow us to quantify the number of users and thus perform the measurement and statistical analysis of the use made by users of the service offered. To do this, we analyze your browsing on our website in order to improve the offer of products or services we offer you.</Trans></li>
            <li><Trans i18nKey='content-02-07'>Advertising cookies: These are those that, well treated by us or by third parties, allow us to manage in the most efficient way possible the offer of the advertising spaces that are on the website, adapting the content of the advertisement to the content of the requested service or to the use that you make of our web page. For this we can analyze your browsing habits on the Internet and we can show you advertisements related to your browsing profile.</Trans></li>
            <li><Trans i18nKey='content-02-08'>Cookies of behavioral advertising: Are those that, well treated by us or by third parties, allow us to manage, in the most efficient way possible, the advertising spaces that, where appropriate, may be included in this Site from which it is provided the service requested. These cookies store information on their behavior obtained through the continuous observation of their browsing habits, which allows us to develop a specific profile to display the advertising based on it.</Trans></li>
          </ul>
        </section>
        <section>
          <h1>{t('Third party cookies')}</h1>
          <p>
            <Trans i18nKey='content-03-01'>
            To display advertising and track our marketing communications, The Web may use third-party media. They use cookies to measure the effectiveness of the ads and personalize their content. The information that can be collected by third parties may include data such as geographical location (through the IP address), contact data or, only if it is entered through this Site, the email address.
            </Trans>
          </p>
        </section>
        <section>
          <h1>{t('Monitoring and Analysis')}</h1>
          <p>
            <Trans i18nKey='content-04-01'>
            For the continuous optimization of its marketing communications, The Web uses analytical software Google Analitycs. This technology allows to know the visitor's online behavior in terms of time, geographical location and use of this Site. The information is collected through tracking cookies. This information is anonymous, is not linked to personal data and the Web does not share it with third parties for its independent use. All the necessary information for the analysis is stored in the servers in Google.
            </Trans>
          </p>
        </section>
      </div>

    )}
  </NamespacesConsumer>
)
